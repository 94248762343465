/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2025-02-12 14:11:36
 * @FilePath: \qcwp-vue\packages\web\src\logic\common.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { ListState, OmitPageParam, PaginationOption, PaginationParams, PaginationResponse } from '@qcwp/common'
import { PaginationStatus, getPaginationListState } from '@qcwp/common'
import type { FetchResponseType } from '~~/src/server/request/type'

/**
 * @param key useAsyncData key prefix
 * @param fn get list data function
 * @param option pagination option
 */
export function LogicTemplateList<LIST_PARAMS extends PaginationParams, LIST_RESPONSE>(
  key: string,
  fn: (params: LIST_PARAMS) => Promise<FetchResponseType<PaginationResponse<LIST_RESPONSE[]>>>,
  option: PaginationOption = { pageSize: 12 },
  filter: (list: LIST_RESPONSE[], data: LIST_RESPONSE[]) => LIST_RESPONSE[] = (_, data) => data,
) {
  const PAGE_SIZE = option.pageSize || 12

  const list = ref<LIST_RESPONSE[]>([])
  const pagination = usePaginationNuxt({ ...option, pageSize: PAGE_SIZE })
  const state = computed<ListState>(() => getPaginationListState(pagination.pagination.status))
  function updatePageData(data: LIST_RESPONSE[]) {
    if (pagination.loadType === 'pageNumber') {
    // @ts-expect-error
      list.value = data
    }
    else if (pagination.loadType === 'up') {
      const newData = filter(list.value as LIST_RESPONSE[], data)
      // @ts-expect-error
      list.value.push(...(newData || []))
    }
  }
  async function clientHandler(params: OmitPageParam<LIST_PARAMS>, page?: number, isRefresh = false) {
    isRefresh && (pagination.setPage(1))
    isRefresh && pagination.setPaginationStatus(PaginationStatus.INIT)
    const { list: newList } = await pagination.loadPaginationData(fn, params, page) || {}
    // console.log(newList, 'newList', params)

    if (newList) {
      !isRefresh && updatePageData(newList)
      // @ts-expect-error
      isRefresh && (list.value = newList)
    }
    // const { list } = await pagination.loadPaginationData(fn, params, page) || {}
    // if (list)
    //   updatePageData(list)
  }
  async function serverHandler(params: OmitPageParam<LIST_PARAMS>, page?: number) {
    const asyncData = await pagination.loadPaginationAsyncData(`${key}_${qs.stringify(params)}`, fn, params, page)
    if (!asyncData)
      return
    const { data } = useNuxtAsyncHandle(asyncData) || {}
    data && updatePageData(data.list || [])
  }

  return {
    PAGE_SIZE,
    list,
    state,
    pagination,
    clientHandler,
    serverHandler,
  }
}
